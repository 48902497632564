.clickable
    &:hover
        cursor: pointer

.circle
    position: relative
    background-color: white
    padding: var(--standard-padding)
    border-radius: 50%
    aspect-ratio: 1 / 1
    img, .mat-icon
        width: 50%
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.icon-text-align
    display: flex
    align-items: center

.no-wrap
    white-space: nowrap
