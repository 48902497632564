// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url(./assets/fonts/Nunito-VariableFont_wght.ttf) format("truetype");
}

$md-primary: (
    50 : #e6e9ec,
    100 : #c1c8cf,
    200 : #98a4af,
    300 : #6f7f8f,
    400 : #506377,
    500 : #31485f,
    600 : #2c4157,
    700 : #25384d,
    800 : #1f3043,
    900 : #132132,
    A100 : #72aeff,
    A200 : #3f90ff,
    A400 : #0c73ff,
    A700 : #0066f1,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #fce3e4,
    100 : #f7b8bc,
    200 : #f28990,
    300 : #ec5964,
    400 : #e83642,
    500 : #e41221,
    600 : #e1101d,
    700 : #dd0d18,
    800 : #d90a14,
    900 : #d1050b,
    A100 : #fffafa,
    A200 : #ffc7c8,
    A400 : #ff9495,
    A700 : #ff7b7c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-background: (
    50 : #f8fbff,
    100 : #edf5fe,
    200 : #e2effd,
    300 : #d6e9fc,
    400 : #cde4fc,
    500 : #c4dffb,
    600 : #bedbfa,
    700 : #b6d7fa,
    800 : #afd2f9,
    900 : #a2caf8,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$neogallery-primary: mat.m2-define-palette($md-primary);
$neogallery-accent: mat.m2-define-palette($md-accent);
$neogallery-background: mat.m2-define-palette($md-background);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$neogallery-theme: mat.m2-define-light-theme((
  color: (
    primary: $neogallery-primary,
    accent: $neogallery-accent
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($neogallery-theme);

:root {
  --primary: #{mat.m2-get-color-from-palette($neogallery-primary)};
  --accent: #{mat.m2-get-color-from-palette($neogallery-accent)};
  --background: #{mat.m2-get-color-from-palette($neogallery-background)};

  --standard-padding: 16px;
  --double-padding: 32px;
  --big-padding: 256px;
}

html {
  height: 100%;
  overflow: hidden;
  color: var(--primary);
}

body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;

  background-color: var(--background);
}

h1, h2, h3, h4 {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
}
